import React from "react"
import Layout from "../components/layout"
export default () => (
  <Layout>
    <h2>Cennik</h2>
    <ul>
      <li>pierwsza konsultacja z rodzicem, rodzicami – 180 zł ( 60-80 min.)</li>

      <li>
        ocena rozwoju psychoruchowego małego dziecka – 100zł ( 60 min.; 1-2
        spotkania + omówienie wyników)
      </li>
      <li>
        diagnoza neuropsychologiczna – 100 zł ( 60 min., od 1 do 3 spotkań +
        omówienie wyników)
      </li>
      <li>terapia funkcji poznawczych – 80 zł ( 50 min.)</li>
      <li>
        terapia dzieci z zaburzeniami ze spektrum autyzmu – 80 zł ( 50 min.)
      </li>
    </ul>
    <p>
      W przypadku regularnej terapii istnieje możliwość ustalenia indywidualnej
      ceny, cennika.
    </p>
  </Layout>
)
